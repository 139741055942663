<template>
  <div class="buildings">
    <MobileTable
      v-model:selection="store.state.resident.selectedResidents"
      :label="$route.name"
      :columns="resident.columns"
      :data="store.state.resident.residents"
    />
    <DesktopTable
      v-model:selection="store.state.resident.selectedResidents"
      :label="$route.name"
      :columns="resident.columns"
      :data="store.state.resident.residents"
    />
    <Actions :items="resident.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/residentes/nuevo");
    };
    /* const onModify = () => {
      if (!store.state.resident.selectedResidents.length) {
          store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        router.push("/residentes/modificar");
      }
    }; */
    const onApartments = () => {
      if (store.state.resident.selectedResidents.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        router.push("/residentes/apartamentos/asociar");
      }
    };
    const onChangeStatus = async (status) => {
      if (!store.state.resident.selectedResidents.length) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);

        const users = store.state.resident.selectedResidents.map(
          (item) => item.userId
        );
        if (users.includes(store.state.auth.user.id)) {
          store.state.toast.add({
            severity: "info",
            summary: "",
            detail: "No se puede modificar el usuario que ha iniciado sesión!",
            life: 5000,
          });
          return;
        }

        const response = await store.dispatch(actions.residentActions.status, {
          users,
          status,
        });

        if (response.ok) {
          store.commit("setSelectedResidents", []);
          await store.dispatch(
            actions.residentActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const resident = reactive({
      columns: [
        {
          field: "name",
          header: "Nombre",
          sort: true,
        },
        {
          field: "identity",
          header: "Rut / CI",
          sort: true,
        },
        {
          field: "email",
          header: "Email",
        },
        {
          field: "phone",
          header: "Teléfono",
        },
        {
          field: "active",
          header: "Habilitado",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/residentes/nuevo"),
        },
        /* {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/residentes/modificar"),
        }, */
        {
          name: "Habilitar",
          action: () => onChangeStatus(true),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/residentes/nuevo"),
        },
        {
          name: "Deshabilitar",
          action: () => onChangeStatus(false),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/residentes/nuevo"),
        },
        {
          name: "Apartamentos",
          action: onApartments,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/residentes/apartamentos/asociar"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedResidents", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.residentActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, resident };
  },
};
</script>

<style scoped></style>
